import jsPDF from "jspdf";
import 'jspdf-autotable';
import * as XLSX from "xlsx";

import dayjs, { Dayjs } from 'dayjs';
import aacorplogo from "../images/aacorp1.png";
import { toast } from "react-toastify";
import { formatTimestamp } from "./CommonFunction";

function calculateFlow(_data: any, i: any) {
    if (i === _data.length - 1) {
      return getSanitizedValue(0);
    }
    const currentData = _data[i];
    const previousData = _data[i + 1];

    if (!previousData) {
      return getSanitizedValue(0); // or some other default value
    }

    if (
      currentData["Differential Total Flow"] === "ERROR" ||
      previousData["Differential Total Flow"] === "ERROR"
    ) {
      return getSanitizedValue(0);
    }

    const currentFlow =
      currentData["Differential Total Flow"] !== "ERROR"
        ? +currentData["Differential Total Flow"]
        : 0;
    const previousFlow =
      previousData["Differential Total Flow"] !== "ERROR"
        ? +previousData["Differential Total Flow"]
        : 0;
    const timeDiff =
      +new Date(currentData.timeStamp) - +new Date(previousData.timeStamp);
    let flow = ((currentFlow - previousFlow) / timeDiff) * 1000 * 60 * 60;
    if (flow < 0) {
      flow = 0;
    }
    const v = getSanitizedValue(flow * 0.001);
    if (isNaN(v)) {
      return 0;
    }

    return v;
}

function getSanitizedValue(value: any) {
    if (typeof value === "number") {
      return value.toFixed(3);
    } else {
      return value;
    }
}
// code metaer Reading
export const downloadExcelReportForMeterReadin = (selectedDevices: string[], apiData: any, startDate: any, endDate: any, users: any) => {

    if (selectedDevices.length === 0) {
        toast.error("Please select at least one device");
        return;
    }

    // Resolve dynamic values
    const userName = users?.user?.name || '';
    const formattedStartDate = startDate.format("DD-MM-YYYY HH:mm:ss");
    const formattedEndDate = endDate.format("DD-MM-YYYY HH:mm:ss");

    const workbook = XLSX.utils.book_new();
    let sheetIndex = 1;

    selectedDevices.forEach((deviceName: string | undefined) => {
        const deviceData = apiData.find((device: { deviceName: string; }) => device.deviceName === deviceName);

        if (deviceData && deviceData.data && Array.isArray(deviceData.data.data)) {
            const { headers, rows } = generateReportExecelForMetadata(deviceData);

            if (headers && rows) {
              const worksheet = XLSX.utils.aoa_to_sheet([
                ["Report Generated", userName], 
                ["Device name",deviceName], 
               
                ["Start time",formattedStartDate, "End Time",formattedEndDate], 
                ["", "", "", "", "", "", "", ""], // Empty row for visual separation
                headers,
                ...rows,
            ]);
            
            if (!worksheet['!merges']) worksheet['!merges'] = [];
            worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 0 } }); 
            worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 0 } }); // Merge cells for Device name
            worksheet['!merges'].push({ s: { r: 2, c: 0 }, e: { r: 2, c: 0 } }); // Merge cells for deviceName
            // worksheet['!merges'].push({ s: { r: 3, c: 0 }, e: { r: 3, c: 0 } }); // Merge cells for Start time
            
                worksheet['!cols'] = [
                    { wch: 20 }, // Column A
                    { wch: 20 }, // Column B
                    { wch: 20 }, // Column C
                    { wch: 20 }, // Column D
                    { wch: 20 }, // Column E
                    { wch: 20 }, // Column F
                    { wch: 20 }, // Column G
                    { wch: 20 }, // Column H
                ];

                XLSX.utils.book_append_sheet(workbook, worksheet, deviceName);
                sheetIndex++;
            }
        } else {
            console.error(`Device data not found for device: ${deviceName}`);
        }
    });

    const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
    });

    const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "report.xlsx";
    link.click();
};

export const generateReportExecelForMetadata = (deviceData: { template: any; data: { data: any[]; }; }) => {
  //console.log('deviceDataexcel', deviceData)
  let headers = [];
  let rows = [];

  switch (deviceData.template) {
    case 5:
      headers = ["Timestamp", "Flow", "Flow Unit", "Total", "Total Unit"];
      rows = deviceData.data.data.map(
        (d: { [x: string]: string | number; timeStamp: string; }, i: any) => [
          formatTimestamp(d.timeStamp),
          calculateFlow(deviceData.data.data, i),
          "m³/hr",
          d["Forward Total Flow"] !== "ERROR"
            ? getSanitizedValue(+d["Forward Total Flow"] * 0.001)
            : "ERROR",
          "m³",
        ]
      );
      break;

    case 6:
      headers = ["Timestamp", "Flow", "Flow Unit", "Total", "Total Unit", "RSSI"];
      rows = deviceData.data.data.map(
        (d: { [x: string]: string | number; timeStamp: string; rssi: any; }, i: any) => [
          formatTimestamp(d.timeStamp),
          calculateFlow(deviceData.data.data, i),
          "m³/hr",
          d["Total Flow"] !== "ERROR"
            ? getSanitizedValue(+d["Total Flow"] * 0.001)
            : "ERROR",
          "m³",
          d.rssi,
        ]
      );
      break;

    case 7:
      headers = ["Timestamp", "Temperature", "Humidity", "RSSI"];
      rows = deviceData.data.data.map(
        (d: { [x: string]: string | number; timeStamp: string; rssi: any; }) => [
          formatTimestamp(d.timeStamp),
          d["Temperature"] !== "ERROR" ? getSanitizedValue(+d["Temperature"]) : "ERROR",
          d["Humidity"] !== "ERROR" ? getSanitizedValue(+d["Humidity"]) : "ERROR",
          d.rssi,
        ]
      );
      break;
      case 8:
        case 22:
        case 23:
          headers = ["Timestamp", "X", "Y", "Z"];
          rows = deviceData.data.data.map((d: any) => [
            formatTimestamp(d.timeStamp),
            parseFloat(d["x"]),
            parseFloat(d["y"]),
            parseFloat(d["z"]),
          ]);
          break;
    case 10:
      headers = ["Timestamp", "Temperature", "Humidity"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["Temperature"],
        d["Humidity"],
      ]);
      break;

    case 12:
      headers = ["Timestamp", "NI", "PH", "TDS", "TEMP"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["NI"],
        d["PH"],
        d["TDS"],
        d["TEMP"],
      ]);
      break;

    case 13:
      headers = ["Timestamp", "CL", "PH", "TDS", "TEMP", "TURB"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["CL"],
        d["PH"],
        d["TDS"],
        d["TEMP"],
        d["TURB"],
      ]);
      break;

    case 14:
      headers = ["Timestamp", "Active Energy (KWH)", "Reactive Energy (KVARH)", "Apparent Energy (KVAH)"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["active_energy"] !== "ERROR" ? getSanitizedValue(+d["active_energy"]) : "ERROR",
        d["reactive_energy"] !== "ERROR" ? getSanitizedValue(+d["reactive_energy"]) : "ERROR",
        d["apparent_energy"] !== "ERROR" ? getSanitizedValue(+d["apparent_energy"]) : "ERROR",
      ]);
      break;

    case 15:
      headers = ["Timestamp", "Flow", "Totalizer"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["Flow"],
        d["Totalizer"],
      ]);
      break;

    case 16:
      headers = ["Timestamp", "Flow", "Totalizer", "Pump Status"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["Flow"],
        d["Totalizer"],
        +d["Pump Status"] === 1 ? "On" : "Off",
      ]);
      break;

    case 17:
      headers = ["Timestamp", "PH", "TEMP"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["PH"],
        d["TEMP"],
      ]);
      break;

    case 18:
      headers = ["Timestamp", "TDS", "TEMP"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["TDS"],
        d["TEMP"],
      ]);
      break;

    case 19:
      headers = ["Timestamp", "CL", "TURB", "TEMP"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["CL"],
        d["TURB"],
        d["TEMP"],
      ]);
      break;

    case 20:
      headers = ["Timestamp", "Temperature", "Humidity"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["Temperature"],
        d["Humidity"],
      ]);
      break;

    case 21:
      headers = ["Timestamp", "Device Status"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        +d["device_status"] === 1 ? "On" : "Off",
      ]);
      break;

    case 24:
      headers = ["Timestamp", "Flow", "Flow Unit", "Totalizer", "Totalizer Unit"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["Inst_flow_rate"],
        "m³/hr",
        d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
        "m³",
      ]);
      break;

    case 25:
      headers = ["Timestamp", "Energy Generation", "Energy Consumption", "Fuel Consumed", "Total Run Hours", "Tank Level"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["ene_gener"],
        d["ene_cons"],
        d["fuel_cons"],
        d["total_run_hr"],
        d["tank_level"],
      ]);
      break;

    case 26:
      headers = ["Timestamp", "Mppt Power", "Mppt Voltage", "Mppt Current"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["mppt_po"],
        d["mppt_vtg"],
        d["mppt_crt"],
      ]);
      break;

    case 27:
      headers = ["Timestamp", "Flow", "Flowrate", "Totalizer", "Forward Totalizer", "Reverse Totalizer", "Battery Level", "Unit"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["Inst_flow"],
        d["Inst_flow_rate"],
        d["Cumu_total"],
        d["Pos_Cumu_Int"] + "." + d["Pos_Cumu_Frac"],
        d["Rev_Cumu_Dec"],
        d["Batt_lvl"],
        "m³",
      ]);
      break;

    case 28:
      headers = ["Timestamp", "Temperature"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["Temperature"],
      ]);
      break;
    
    case 29:
      headers = ["Timestamp", "Level", "Unit"];
      rows = deviceData.data.data.map((d: { timeStamp: string; Level: string | number; logId: string; }) => [
        formatTimestamp(d.timeStamp),
        d["Level"] !== "ERROR" ? getSanitizedValue(+d["Level"]) : "ERROR",
        "cm",
      ]);
      break;

    case 31:
      headers = ["Timestamp", "Totalizer", "Unit"];
      rows = deviceData.data.data.map((d: any) => [
        formatTimestamp(d.timeStamp),
        d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
        "m³",
      ]);
      break;

    default:
      console.error(`Unknown template ID: ${deviceData.template}`);
      return { headers: [], rows: [] };
  }

  rows = rows.filter((d: any) => {
    // Function to check if any value contains "error" (case insensitive)
    const containsError = (value: any) =>
      typeof value === "string" &&
      value.toLowerCase().includes("error");

    // Check if any value in the object contains "error" (case insensitive)
    return !Object.values(d).some(containsError);
  });

  return { headers, rows };
};
  
export function generateReportPDFForMetadata(deviceData: any, startY: number, doc: any) {
    //console.log('deviceData',deviceData)
    if (!deviceData || !deviceData.data || !deviceData.data.data) {
      doc.text("No data available for this device.", 13, startY);
      return;
    }
  
    let rows = [];
    let headers = [];
  
    switch (deviceData.template) {
      case 5:
        headers = [
          ["Timestamp", "Flow", "Flow Unit", "Total",  "Total Unit", ],
        ];
        rows = deviceData.data.data.map((d: { [x: string]: string | number; timeStamp: string; rssi: any; }, i: any) => [
          formatTimestamp(d.timeStamp),
          calculateFlow(deviceData.data.data, i),
          "m³/hr",
          d["Forward Total Flow"] !== "ERROR" ? getSanitizedValue(+d["Forward Total Flow"] * 0.001) : "ERROR",
          "m³",
          
        ]);
        break;
  
        case 6:
          headers = [
            ["Timestamp", "Level"],
          ];
          rows = deviceData.data.data.map((d: { [x: string]: string | number; timeStamp: string; rssi: any; }, i: any) => {
            return {
              Timestamp: formatTimestamp(d.timeStamp),
              Level: d["Piezo Level"]!== "ERROR"? getSanitizedValue(+d["Piezo Level"]) : "ERROR",
            };
          });
          break;
          case 7:
            headers = [["Timestamp", "DO", "PH", "TDS", "TEMP"]];
            rows = deviceData.data.data.map((d: any) => {
              return {
                Timestamp: formatTimestamp(d.timeStamp),
                DO: d["DO"],
                PH: d["PH"],
                TDS: d["TDS"],
                TEMP: d["TEMP"],
              };
            });
            break;
            case 8:
              case 22:
              case 23:
                headers = ["Timestamp", "X", "Y", "Z"];
                rows = deviceData.data.data.map((d: any) => [
                  formatTimestamp(d.timeStamp),
                  parseFloat(d["x"]),
                  parseFloat(d["y"]),
                  parseFloat(d["z"]),
                ]);
                break;
          case 10:
            headers = [["Timestamp", "Temperature", "Humidity"]];
            rows = deviceData.data.data.map((d: any) => {
              return {
                Timestamp: formatTimestamp(d.timeStamp),
                Temperature: d["Temperature"],
                Humidity: d["Humidity"],
              };
            });
            break;
          
          case 12:
            headers = [["Timestamp", "NI", "PH", "TDS", "TEMP"]];
            rows = deviceData.data.data.map((d: any) => {
              return {
                Timestamp: formatTimestamp(d.timeStamp),
                NI: d["NI"],
                PH: d["PH"],
                TDS: d["TDS"],
                TEMP: d["TEMP"],
              };
            });
            break;
          
          case 13:
            headers = [["Timestamp", "CL", "PH", "TDS", "TEMP", "TURB"]];
            rows = deviceData.data.data.map((d: any) => {
              return {
                Timestamp: formatTimestamp(d.timeStamp),
                CL: d["CL"],
                PH: d["PH"],
                TDS: d["TDS"],
                TEMP: d["TEMP"],
                TURB: d["TURB"],
              };
            });
            break;
                
  case 14:
  headers = [
    ["Timestamp", "Active Energy (KWH)", "Reactive Energy (KVARH)", "Apparent Energy (KVAH)"],
  ];
  rows = deviceData.data.data.map((d: any) => [
    formatTimestamp(d.timeStamp),
    d["active_energy"]!== "ERROR"? getSanitizedValue(+d["active_energy"]) : "ERROR",
    d["reactive_energy"]!== "ERROR"? getSanitizedValue(+d["reactive_energy"]) : "ERROR",
    d["apparent_energy"]!== "ERROR"? getSanitizedValue(+d["apparent_energy"]) : "ERROR",
  ]);
  break;
  case 15:
    headers = [["Timestamp", "Flow", "Totalizer"]];
    rows = deviceData.data.data.map((d: any) => {
      return {
        Timestamp: formatTimestamp(d.timeStamp),
        Flow: d["Flow"],
        Totalizer: d["Totalizer"],
      };
    });
    break;
  
  case 16:
    headers = [["timestamp", "flow", "totalizer", "pumpstatus"]];
    rows = deviceData.data.data.map((d: any) => {
      return {
        timestamp: formatTimestamp(d.timeStamp),
        flow: d["Flow"],
        totalizer: d["Totalizer"],
        pumpstatus: +d["Pump Status"] === 1 ? "On" : "Off",
      };
    });
    break;
  
  case 17:
    headers = [["timestamp", "ph", "temp"]];
    rows = deviceData.data.data.map((d: any) => {
      return {
        timestamp: formatTimestamp(d.timeStamp),
        ph: d["PH"],
        temp: d["TEMP"],
      };
    });
    break;
  
  case 18:
    headers = [["timestamp", "tds", "temp"]];
    rows = deviceData.data.data.map((d: any) => {
      return {
        timestamp: formatTimestamp(d.timeStamp),
        tds: d["TDS"],
        temp: d["TEMP"],
      };
    });
    break;
    case 19:
      headers = [["timestamp", "cl", "turb", "temp"]];
      rows = deviceData.data.data.map((d: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          cl: d["CL"],
          turb: d["TURB"],
          temp: d["TEMP"],
        };
      });
      break;
    case 20:
    headers = [["timestamp", "temperature", "humidity"]];
    rows = deviceData.data.data.map((d: any) => {
      return {
        timestamp: formatTimestamp(d.timeStamp),
        temperature: d["Temperature"],
        humidity: d["Humidity"],
      };
    });
    break;
  
  case 21:
    headers = [["timestamp", "devicestatus"]];
    rows = deviceData.data.data.map((d: any) => {
      return {
        timestamp: formatTimestamp(d.timeStamp),
        devicestatus: +d["device_status"] === 1 ? "On" : "Off",
      };
    });
    break;
  case 24:
  headers = [
    ["Timestamp", "Flow", "Flow Unit", "Totalizer", "Totalizer Unit"],
  ];
  rows = deviceData.data.data.map((d: any) => {
    return {
      Timestamp: formatTimestamp(d.timeStamp),
      Flow: d["Inst_flow_rate"],
      "Flow Unit": "m³/hr",
      Totalizer: d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
      "Totalizer Unit": "m³",
    };
  });
  break;
  case 25:
    headers = [
      ["Timestamp", "Energy Generation", "Energy Consumption", "Fuel Consumed", "Total Run Hours", "Tank Level"],
    ];
    rows = deviceData.data.data.map((d: any) => {
      return {
        Timestamp: formatTimestamp(d.timeStamp),
        "Energy Generation": d["ene_gener"],
        "Energy Consumption": d["ene_cons"],
        "Fuel Consumed": d["fuel_cons"],
        "Total Run Hours": d["total_run_hr"],
        "Tank Level": d["tank_level"],
      };
    });
    break;
  case 26:
  headers = [
    ["Timestamp", "Mppt Power", "Mppt Voltage", "Mppt Current"],
  ];
  rows = deviceData.data.data.map((d: any) => {
    return {
      Timestamp: formatTimestamp(d.timeStamp),
      "Mppt Power": d["mppt_po"],
      "Mppt Voltage": d["mppt_vtg"],
      "Mppt Current": d["mppt_crt"],
    };
  });
  break;
   
  case 27:
  headers = [
    ["Timestamp", "Flow", "Flowrate", "Totalizer", "Forward Totalizer", "Reverse Totalizer", "Battery Level", "Unit"],
  ];
  rows = deviceData.data.data.map((d: any) => {
    return {
      Timestamp: formatTimestamp(d.timeStamp),
      Flow: d["Inst_flow"],
      Flowrate: d["Inst_flow_rate"],
      Totalizer: d["Cumu_total"],
      "Forward Totalizer": d["Pos_Cumu_Int"] + "." + d["Pos_Cumu_Frac"],
      "Reverse Totalizer": d["Rev_Cumu_Dec"],
      "Battery Level": d["Batt_lvl"],
      Unit: "m³",
    };
  });
  break;
  case 28:
  headers = [["Timestamp", "Temperature"]];
  rows = deviceData.data.data.map((d: any) => {
    return {
      Timestamp: formatTimestamp(d.timeStamp),
      Temperature: d["Temperature"],
    };
  });
  break;
  case 29:
          headers = [
            ["Timestamp", "Level", "Unit"],
          ];
          rows = deviceData.data.data.map((d: { 
            timeStamp: string; 
            Level: string | number; 
            logId: string;
          }) => [
            formatTimestamp(d.timeStamp),
            d["Level"] !== "ERROR" ? getSanitizedValue(+d["Level"]) : "ERROR",
            "cm", 
          ]);
          break;
       
          
  case 31:
  headers = [
    ["Timestamp", "Totalizer", "Unit"],
  ];
  rows = deviceData.data.data.map((d: any) => {
    return {
      Timestamp: formatTimestamp(d.timeStamp),
      Totalizer: d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
      Unit: "m³",
    };
  });
  break;
  
  default:
        doc.text(`Unknown template ID: ${deviceData.template}`, 13, startY);
        return;
    }
    rows = rows.filter((d: any) => {
      // Function to check if any value contains "error" (case insensitive)
      const containsError = (value: any) =>
        typeof value === "string" &&
        value.toLowerCase().includes("error");
  
      // Check if any value in the object contains "error" (case insensitive)
      return !Object.values(d).some(containsError);
    });
  
    (doc as any).autoTable({
      head: headers,
      body: rows,
      startY: startY,
    });
}

export const downloadPDFMetadata = (selectedDevices: string[], apiData: any, startDate: any, endDate: any, users: any) => {
  try {
    const doc = new jsPDF();
    const fontSize = 8; // Reduce font size
    let fontStyle = "helvetica";
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const responsiveFontSize = fontSize * (pageWidth / 210);
    doc.setFont(fontStyle);
    doc.setFontSize(responsiveFontSize);

    // Add logo
    const logoWidth = 40;
    const logoHeight = 20;
    doc.addImage(aacorplogo, "JPEG", 12, 3, logoWidth, logoHeight);

    // Set font styles
    doc.setFontSize(14);
    doc.text("A & A CORPORATION PVT. LTD.", 120, 20);

    // Set header styles
    doc.setFontSize(18);
    doc.setFont("", "bold");
    doc.text("Summary", pageWidth / 2, 35, {
      align: "center",
    });

    doc.setFontSize(14);
    doc.setFont("", "normal");

    let startY = 47;
    let isFirstDevice = true; // Flag to indicate the first device
    selectedDevices.forEach((deviceName: string, index: number) => {
      const deviceData = apiData.find(
        (device: { deviceName: string; }) => device.deviceName === deviceName
      );
      if (index > 0) {
        doc.addPage(); // Add a new page for each device after the first one
        startY = 20; // Reset startY for each new page
      }

      if (deviceData && deviceData.data && Array.isArray(deviceData.data.data) && deviceData.data.data.length > 0) {
        const reportDuration = `${deviceData.data.data[0].timeStamp} - ${
          deviceData.data.data[deviceData.data.data.length - 1].timeStamp
        }`;
        doc.text(`Report Duration: ${reportDuration}`, 13, startY + 1);

        startY += 10;
        doc.text(`Device Name: ${deviceData.deviceName}`, 13, startY);

        startY += 10;

        generateReportPDFForMetadata(deviceData, startY, doc);
      } else {
        // If no data is present for the device, add a message to the PDF
        doc.text(`No data present for device ${deviceName}`, 13, startY);
        startY += 10;
      }
    });

    // Add footer styles
    doc.setFontSize(10);
    const generatedOnText = `Report Generated On: ${new Date().toLocaleString()}`;
    const generatedOnTextY = pageHeight - 10; // Adjust 10 as needed
    doc.text(generatedOnText, 20, generatedOnTextY);

    // Get the user name from the users state
    const userName = users?.user?.name; // Assuming there's only one user in the users array

    // Add the user name to the report
    const reportGeneratedByText = `Report Generated By: ${userName}`;
    doc.text(reportGeneratedByText, 20, generatedOnTextY + 5);

    doc.save("report.pdf");
  } catch (error) {
    console.error(error);
  }
}


// code for Consumption for custom excel and pdf 

// function generateReportConsumption(deviceData: { data: { data: any; }; template: any; deviceName: any; }, sensor: string) {
//   if (!deviceData || !deviceData.data || !deviceData.data.data) {
//     return null;
//   }

//   let headers = [];
//   let row = [];

//   switch (deviceData.template) {
//     case 5:
//       headers = ["Device Name", "First Reading", "Last Reading", "Consumption"];
//       const data5 = deviceData.data.data;
//       if (data5.length <= 0) {
//         return null;
//       }

//       let firstReading5 = data5[data5.length - 1]["Forward Total Flow"] * 0.001;
//       let lastReading5 = data5[0]["Forward Total Flow"] * 0.001;
//       let consumption5 = lastReading5 - firstReading5;
      
//       if (consumption5 < 0) {
//         consumption5 = Math.abs(consumption5);
//       }

//       row = [
//         deviceData.deviceName,
//         firstReading5.toFixed(3),
//         lastReading5.toFixed(3),
//         consumption5.toFixed(3),
//       ];
     
//       break;

//     case 14:
//       headers = ["Device Name", "First Reading", "Last Reading", "Consumption"];
//       const data14 = deviceData.data.data;
//       if (data14.length <= 0) {
//         console.log("No data for template 14");
//         return null;
//       }

//       let sensorKey = '';
//       switch (sensor.toLowerCase()) {
//         case 'activeenergy(kwh)':
//           sensorKey = 'active_energy';
//           break;
//         case 'reactiveenergy(kvarh)':
//           sensorKey = 'reactive_energy';
//           break;
//         case 'apparentenergy(kvah)':
//           sensorKey = 'apparent_energy';
//           break;
//       }

//       let firstReading14 = getSanitizedValue(+data14[data14.length - 1][sensorKey]);
//       let lastReading14 = getSanitizedValue(+data14[0][sensorKey]);
//       let energyConsumption = 0;
      
//       if (firstReading14 !== "ERROR" && lastReading14 !== "ERROR") {
//         energyConsumption = Math.abs(lastReading14 - firstReading14);
        
//         row = [
//           deviceData.deviceName,
//           typeof firstReading14 === 'number' ? firstReading14.toFixed(3) : 'N/A',
//           typeof lastReading14 === 'number' ? lastReading14.toFixed(3) : 'N/A',
//           energyConsumption.toFixed(3),
//         ];
//       } else {
//         row = [
//           deviceData.deviceName,
//           'N/A',
//           'N/A',
//           'N/A',
//         ];
//       }

//       console.log("Processed data for template 14:", row);
//       break;
    
//     case 24:
//     case 31:
//       headers = ["Device Name", "First Reading", "Last Reading", "Consumption"];
//       const data = deviceData.data.data;
//       console.log('data', data);
//       if (data.length <= 0) {
//         return null;
//       }
  
//       let firstReading = parseFloat(data[data.length - 1].Pos_Cumu_flow_Int + "." + data[data.length - 1].Pos_Cumu_flow_Dec);
//       let lastReading = parseFloat(data[0].Pos_Cumu_flow_Int + "." + data[0].Pos_Cumu_flow_Dec);
  
//       console.log('firstReading', firstReading);
//       let consumption = (lastReading - firstReading) * 0.001;
      
//       if (consumption < 0) {
//         consumption = Math.abs(consumption);
//       }
  
//       row = [
//         deviceData.deviceName,
//         firstReading.toFixed(3),
//         lastReading.toFixed(3),
//         consumption.toFixed(3),
//       ];
//       break;

//     default:
//       return null;
//   }

//   return { headers, row };
// }
function generateReportConsumption(deviceData: { data: { data: any; }; template: any; deviceName: any; }, sensor: string) {
  if (!deviceData || !deviceData.data || !deviceData.data.data) {
    return null;
  }

  let headers = ["Device Name", "First Reading", "Last Reading", "Consumption"];
  let row = [];

  const data = deviceData.data.data;
  if (data.length <= 0) {
    return null;
  }

  let firstReading = 'N/A';
  let lastReading = 'N/A';
  let consumption = 0;

  switch (deviceData.template) {
    case 5:
      firstReading = (data[data.length - 1]["Forward Total Flow"] * 0.001).toFixed(3);
      lastReading = (data[0]["Forward Total Flow"] * 0.001).toFixed(3);
      consumption = Math.abs(parseFloat(lastReading) - parseFloat(firstReading));
      break;

    case 14:
      let sensorKey = '';
      switch (sensor.toLowerCase()) {
        case 'activeenergy(kwh)':
          sensorKey = 'active_energy';
          break;
        case 'reactiveenergy(kvarh)':
          sensorKey = 'reactive_energy';
          break;
        case 'apparentenergy(kvah)':
          sensorKey = 'apparent_energy';
          break;
      }
      firstReading = getSanitizedValue(data[data.length - 1][sensorKey]);
      lastReading = getSanitizedValue(data[0][sensorKey]);
      if (firstReading !== "ERROR" && lastReading !== "ERROR") {
        consumption = Math.abs(parseFloat(lastReading) - parseFloat(firstReading));
      }
      break;
    
    case 15:
        firstReading = (data[data.length - 1]["Totalizer"]);
        lastReading = (data[0]["Totalizer"]);
        consumption = Math.abs(parseFloat(lastReading) - parseFloat(firstReading));
        break;
      
    case 24:
    case 31:
      firstReading = parseFloat(data[data.length - 1].Pos_Cumu_flow_Int + "." + data[data.length - 1].Pos_Cumu_flow_Dec).toFixed(3);
      lastReading = parseFloat(data[0].Pos_Cumu_flow_Int + "." + data[0].Pos_Cumu_flow_Dec).toFixed(3);
      consumption = Math.abs(parseFloat(lastReading) - parseFloat(firstReading)) * 0.001;
      break;

    default:
      return null;
  }

  row = [
    deviceData.deviceName,
    firstReading,
    lastReading,
    consumption.toFixed(3)
  ];

  return { headers, row };
}

export const generateExcelConsumptionReport = (selectedDevices: string[], apiData: any, startDate: any, endDate: any, users: any, reportPeriodicity: string, sensor: string) => {
  if (selectedDevices.length === 0) {
    // console.log("No devices selected");
    toast.error("Please select at least one device");
    return;
  }

  // console.log("Selected devices:", selectedDevices);
  // console.log("API data:", apiData);

  const workbook = XLSX.utils.book_new();

  let headers: string[] = [];
  let data: any[][] = [];

  // Resolve dynamic values
  const userName = users?.user?.name || '';
  const formattedStartDate = startDate.format("DD-MM-YYYY HH:mm:ss");
  const formattedEndDate = reportPeriodicity === 'hourly' 
    ? startDate.add(1, 'day').format("DD-MM-YYYY HH:mm:ss")
    : endDate.format("DD-MM-YYYY HH:mm:ss");

  //console.log("Report details:", { userName, formattedStartDate, formattedEndDate, reportPeriodicity });

  if (reportPeriodicity === 'Custom') {
    selectedDevices.forEach((deviceName) => {
      const deviceData = apiData.find((device: { deviceName: string; }) => device.deviceName === deviceName);
      // console.log(`Processing device: ${deviceName}`, deviceData);

      if (deviceData && deviceData.data && Array.isArray(deviceData.data.data) && deviceData.data.data.length > 0) {
        const result = generateReportConsumption(deviceData, sensor);
        // console.log(`Result for ${deviceName}:`, result);

        if (result) {
          headers = result.headers;
          data.push(result.row);
        }
      } else {
        //console.log(`No data for device: ${deviceName}`);
        if (headers.length === 0) {
          headers = ["Device Name", "First Reading", "Last Reading", "Consumption"];
        }
        data.push([deviceName, "NA", "NA", "NA"]);
      }
    });
  }

  // console.log("Final headers:", headers);
  // console.log("Final data:", data);

  // Create the worksheet
  let worksheet;

  // Prepare the header rows
  const headerRows = [
    ["Report Header", "Consumption Summary"],
    ["Report Generated", userName],
    ["Report Periodicity", reportPeriodicity],
    ["Report Duration", `${formattedStartDate} to ${formattedEndDate}`],
    [], // Empty row for visual separation
  ];

  if (data.length === 0) {
    //console.log("No data to create worksheet");
    worksheet = XLSX.utils.aoa_to_sheet([
      ...headerRows,
      ["No data present for the selected devices"],
    ]);
  } else {
   // console.log("Creating worksheet with data");
    worksheet = XLSX.utils.aoa_to_sheet([
      ...headerRows,
      headers,
      ...data,
    ]);
  }

  worksheet['!cols'] = headers.map(() => ({ wch: 20 }));

  XLSX.utils.book_append_sheet(workbook, worksheet, "Consumption Summary");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "consumption_summary.xlsx";
  //console.log("Downloading file:", link.download);
  link.click();
};

export const downloadPDFConsumption = (selectedDevices: string[], apiData: any, startDate: any, endDate: any, users: any, reportPeriodicity: string, sensor: string) => {
  try {
    const doc = new jsPDF();
    const fontSize = 8;
    let fontStyle = "helvetica";
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const responsiveFontSize = fontSize * (pageWidth / 210);

    doc.setFont(fontStyle);
    doc.setFontSize(responsiveFontSize);

    // Function to add header
    const addHeader = () => {
      // Add logo
      const logoWidth = 40;
      const logoHeight = 20;
      doc.addImage(aacorplogo, "JPEG", 12, 3, logoWidth, logoHeight);

      // Set header text
      doc.setFontSize(14);
      doc.text("A & A CORPORATION PVT. LTD.", 120, 20);

      // Set header styles
      doc.setFontSize(18);
      doc.setFont("", "bold");
      doc.text("Consumption Summary", pageWidth / 2, 35, { align: "center" });
    };

    // Add header to the first page only
    addHeader();

    doc.setFontSize(14);
    doc.setFont("", "normal");
    let startY = 47;

    const formattedStartDate = startDate.format("DD-MM-YYYY HH:mm:ss");
    const formattedEndDate = reportPeriodicity === 'hourly'
      ? startDate.add(1, 'day').format("DD-MM-YYYY HH:mm:ss")
      : endDate.format("DD-MM-YYYY HH:mm:ss");

    doc.text(`Report Duration: ${formattedStartDate} - ${formattedEndDate}`, 13, startY + 1);
    doc.text(`Report Periodicity: ${reportPeriodicity}`, 13, startY + 8);
    startY += 20;

    let headers: string[] = [];
    let rows: any[][] = [];

    if (reportPeriodicity === 'Custom') {
      selectedDevices.forEach((deviceName) => {
        const deviceData = apiData.find((device: { deviceName: string; }) => device.deviceName === deviceName);
        if (deviceData && deviceData.data && Array.isArray(deviceData.data.data) && deviceData.data.data.length > 0) {
          const result = generateReportConsumption(deviceData, sensor);
          if (result) {
            headers = result.headers;
            rows.push(result.row);
          }
        } else {
          if (headers.length === 0) {
            headers = ["Device Name", "First Reading", "Last Reading", "Consumption"];
          }
          rows.push([deviceName, "NA", "NA", "NA"]);
        }
      });
    }

    if (rows.length > 0) {
      (doc as any).autoTable({
        head: [headers],
        body: rows,
        startY: startY,
      });
    } else {
      doc.text("No data available for selected devices.", 13, startY);
    }

    doc.setFontSize(10);
    const generatedOnText = `Report Generated On: ${new Date().toLocaleString()}`;
    const generatedOnTextY = pageHeight - 10;
    doc.text(generatedOnText, 20, generatedOnTextY);

    const userName = users?.user?.name;
    const reportGeneratedByText = `Report Generated By: ${userName}`;
    doc.text(reportGeneratedByText, 20, generatedOnTextY + 5);

    doc.save("consumption_summary.pdf");
  } catch (error) {
    console.error(error);
  }
};



// code for Consumption for houry excel and pdf

interface HourlyData {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstValue: string;
  lastValue: string;
  hour: string;
  firmwareVersionId?: string;  // Add this line
}

interface HourlyConsumptionData {
  hour: string;
  consumption: number;
  firstReading: number;
  lastReading: number;
  firstTimeStamp: string;
  lastTimeStamp: string;
}

const filterDataForSelectedDate = (data: any[], selectedDate: Dayjs, template: number): HourlyData[] => {
  const startOfDay = selectedDate.startOf('day');
  const endOfDay = selectedDate.endOf('day');

  const selectedDateData = data.filter((item) => {
    if (!item || !item.timeStamp) return false;
    const itemDate = dayjs(item.timeStamp);
    return itemDate.isValid() && itemDate.isSameOrAfter(startOfDay) && itemDate.isSameOrBefore(endOfDay);
  });

  selectedDateData.sort((a, b) => 
    dayjs(a.timeStamp).valueOf() - dayjs(b.timeStamp).valueOf()
  );

  const result: HourlyData[] = [];

  selectedDateData.forEach((item) => {
    const itemDate = dayjs(item.timeStamp);
    const hourTimestamp = itemDate.format('YYYY-MM-DDTHH');
    
    const value = getRelevantValue(item, template);

    const index = result.findIndex((hourlyData) => hourlyData.hour === hourTimestamp);

    if (index === -1) {
      result.push({
        firstTimeStamp: item.timeStamp,
        lastTimeStamp: item.timeStamp,
        firstValue: value,
        lastValue: value,
        hour: hourTimestamp,
      });
    } else {
      result[index].lastTimeStamp = item.timeStamp;
      result[index].lastValue = value;
    }
  });

  return result;
};

const getRelevantValue = (item: any, template: number): string => {
  const normalizedItem = Object.keys(item).reduce((acc, key) => {
    acc[key.toLowerCase().trim()] = item[key];
    return acc;
  }, {} as Record<string, any>);

  if (template === 24 || template === 31) {
    if (normalizedItem['pos_cumu_flow_int'] !== undefined && normalizedItem['pos_cumu_flow_dec'] !== undefined) {
      return `${normalizedItem['pos_cumu_flow_int']}.${normalizedItem['pos_cumu_flow_dec']}`;
    }
  }
  if (normalizedItem['forward total flow'] !== undefined) return normalizedItem['forward total flow'];
  if (normalizedItem['active_energy'] !== undefined) return normalizedItem['active_energy'];
  if (normalizedItem['reactive_energy'] !== undefined) return normalizedItem['reactive_energy'];
  if (normalizedItem['apparent_energy'] !== undefined) return normalizedItem['apparent_energy'];
  if (normalizedItem['totalizer'] !== undefined) return normalizedItem['totalizer'];
  
  console.warn('No relevant value found for item:', item, 'Template:', template);
  return '0';
};

const processHourlyData = (inputData: HourlyData[]): HourlyData[] => {
  const processedData: HourlyData[] = [];

  if (inputData.length > 0) {
    processedData.push({ ...inputData[0] });

    for (let i = 1; i < inputData.length; i++) {
      const currentHour = inputData[i];
      const previousHour = inputData[i - 1];

      processedData.push({
        firstTimeStamp: previousHour.lastTimeStamp,
        lastTimeStamp: currentHour.lastTimeStamp,
        firstValue: previousHour.lastValue,
        lastValue: currentHour.lastValue,
        hour: currentHour.hour,
      });
    }
  }
// console.log('processedData',processedData)
  return processedData;
};

const processConsumptionData = (inputData: HourlyData[], template: number): HourlyConsumptionData[] => {
  return inputData.map((hourData) => {
    const originalFirstReading = parseFloat(hourData.firstValue);
    const originalLastReading = parseFloat(hourData.lastValue);
    let consumption = Math.abs(originalLastReading - originalFirstReading);

    let adjustedFirstReading = originalFirstReading;
    let adjustedLastReading = originalLastReading;
    let adjustedConsumption = consumption;

    if (template === 5) {
      if (hourData.firmwareVersionId === "1") {
        // For airtel network, use values as is
        adjustedFirstReading = parseFloat((adjustedFirstReading / 1000).toFixed(3));
        adjustedLastReading = parseFloat((adjustedLastReading / 1000).toFixed(3));
        adjustedConsumption = parseFloat((adjustedConsumption / 1000).toFixed(3));
      } else {
        // For other networks, divide by 1000
        // adjustedFirstReading = parseFloat((adjustedFirstReading / 1000).toFixed(3));
        // adjustedLastReading = parseFloat((adjustedLastReading / 1000).toFixed(3));
        // adjustedConsumption = parseFloat((adjustedConsumption / 1000).toFixed(3));
      }
    } else if (template !== 14 && template !== 24 && template !== 31) {
      // Convert to kilowatt-hours if not one of these templates
      adjustedFirstReading = parseFloat(adjustedFirstReading.toFixed(3));
      adjustedLastReading = parseFloat(adjustedLastReading.toFixed(3));
      adjustedConsumption = parseFloat(adjustedConsumption.toFixed(3));
    }

    // Apply rounding to 3 decimal places for all cases
    adjustedFirstReading = parseFloat(adjustedFirstReading.toFixed(3));
    adjustedLastReading = parseFloat(adjustedLastReading.toFixed(3));
    adjustedConsumption = parseFloat(adjustedConsumption.toFixed(3));

    const result: HourlyConsumptionData = {
      hour: hourData.hour,
      consumption: adjustedConsumption,
      firstReading: adjustedFirstReading,
      lastReading: adjustedLastReading,
      firstTimeStamp: hourData.firstTimeStamp,
      lastTimeStamp: hourData.lastTimeStamp,
    };

    // console.log(`hour: ${result.hour}`);
    // console.log(`consumption: ${result.consumption}`);
    // console.log(`firstReading: ${result.firstReading}`);
    // console.log(`lastReading: ${result.lastReading}`);
    // console.log(`firstTimeStamp: ${result.firstTimeStamp}`);
    // console.log(`lastTimeStamp: ${result.lastTimeStamp}`);
    // console.log('------------------------');

    return result;
  });
};
// const processConsumptionData = (inputData: HourlyData[], template: number): HourlyConsumptionData[] => {
//   return inputData.map((hourData) => {
//     const originalFirstReading = parseFloat(hourData.firstValue);
//     const originalLastReading = parseFloat(hourData.lastValue);
//     let consumption = Math.abs(originalLastReading - originalFirstReading);

//     console.log('originalFirstReading', originalFirstReading);
//     console.log('originalLastReading', originalLastReading);

//     let adjustedFirstReading = originalFirstReading;
//     let adjustedLastReading = originalLastReading;
//     let adjustedConsumption = consumption;
//   //   if (template === 5) {
//   //     // For template 5, divide by 1000 and then apply rounding to 3 decimal places
//   //     adjustedFirstReading = parseFloat((adjustedFirstReading / 1000).toFixed(3));
//   //     adjustedLastReading = parseFloat((adjustedLastReading / 1000).toFixed(3));
//   //     adjustedConsumption = parseFloat((adjustedConsumption / 1000).toFixed(3));
//   //   }
//   //  else
//     if (template !== 14 && template !== 24 && template !== 31) {
//       // Convert to kilowatt-hours if not one of these templates
//       adjustedFirstReading = parseFloat(adjustedFirstReading.toFixed(3));
//       adjustedLastReading = parseFloat(adjustedLastReading.toFixed(3));
//       adjustedConsumption = parseFloat(adjustedConsumption.toFixed(3));
//     }
//     // else{
//     //   adjustedFirstReading/= 1000;
//     //   adjustedLastReading /= 1000;
//     //   adjustedConsumption /= 1000;
//     // }

//    // Apply rounding to 3 decimal places
//     adjustedFirstReading = parseFloat(adjustedFirstReading.toFixed(3));
//     adjustedLastReading = parseFloat(adjustedLastReading.toFixed(3));
//     adjustedConsumption = parseFloat(adjustedConsumption.toFixed(3));

//     const result: HourlyConsumptionData = {
//       hour: hourData.hour,
//       consumption: adjustedConsumption,
//       firstReading: adjustedFirstReading,
//       lastReading: adjustedLastReading,
//       firstTimeStamp: hourData.firstTimeStamp,
//       lastTimeStamp: hourData.lastTimeStamp,
//     };

//     console.log(`hour: ${result.hour}`);
//     console.log(`consumption: ${result.consumption}`);
//     console.log(`firstReading: ${result.firstReading}`);
//     console.log(`lastReading: ${result.lastReading}`);
//     console.log(`firstTimeStamp: ${result.firstTimeStamp}`);
//     console.log(`lastTimeStamp: ${result.lastTimeStamp}`);
//     console.log('------------------------');

//     return result;
//   });
// };

export const generateExcelConsumptionReportHourly = (
  selectedDevices: string[],
  apiData: any,
  selectedDate: Dayjs,
  users: any,
  reportPeriodicity: string
) => {
  if (selectedDevices.length === 0) {
    console.error("Please select at least one device");
    return;
  }

  const workbook = XLSX.utils.book_new();

  const userName = users?.user?.name || 'Username';
  const formattedDate = selectedDate.format('YYYY-MM-DD');
  const startDate = selectedDate.startOf('day').format("DD-MM-YYYY HH:mm:ss");
  const endDate = selectedDate.endOf('day').format("DD-MM-YYYY HH:mm:ss");

  const headers = [
    ["Report Header", "Consumption Summary"],
    ["Report Generated", userName],
    ["Report Periodicity", reportPeriodicity],
    ["Report Duration", `${startDate} to ${endDate}`],
    ["", ""],
    ["Time", ...selectedDevices]
  ];

  const hourlyData: { [hour: string]: { [device: string]: number } } = {};

  selectedDevices.forEach((deviceName) => {
    const deviceData = apiData.find((device: { deviceName: string; }) => device.deviceName === deviceName);
    if (deviceData && deviceData.data && Array.isArray(deviceData.data.data) && deviceData.data.data.length > 0) {
      const template = deviceData.template;
      const filteredData = filterDataForSelectedDate(deviceData.data.data, selectedDate, template);
      if (filteredData.length > 0) {
        const hourData = processHourlyData(filteredData);
        const consumptionData = processConsumptionData(hourData, template);

        consumptionData.forEach((hourlyConsumption) => {
         // console.log('hourlyConsumption',hourlyConsumption)
          const startHour = dayjs(hourlyConsumption.hour).format('HH:00');
          const endHour = dayjs(hourlyConsumption.hour).add(1, 'hour').format('HH:00');
          const hour = `${startHour} - ${endHour}`;
          if (!hourlyData[hour]) {
            hourlyData[hour] = {};
          }
          // Remove the division by 1000 here
          hourlyData[hour][deviceName] = Number((hourlyConsumption.consumption));
        });
      }
    }
  });

  // Ensure all hours are present in hourlyData
  for (let i = 0; i < 24; i++) {
    const startHour = dayjs().startOf('day').add(i, 'hour').format('HH:00');
    const endHour = dayjs().startOf('day').add(i + 1, 'hour').format('HH:00');
    const hour = `${startHour} - ${endHour}`;
    if (!hourlyData[hour]) {
      hourlyData[hour] = {};
    }
    selectedDevices.forEach(device => {
      if (hourlyData[hour][device] === undefined) {
        hourlyData[hour][device] = 0;
      }
    });
  }

  const data = Object.entries(hourlyData).map(([hour, devices]) => {
    //console.log('hourlyData',hourlyData)
    return [
      hour,
      ...selectedDevices.map(device => {
        const value = devices[device];
        return typeof value === 'number' ? value.toFixed(3) : 'NA';
      })
    ];
  });

  data.sort((a, b) => {
    const hourA = dayjs(a[0].split(' - ')[0], 'HH:mm');
    const hourB = dayjs(b[0].split(' - ')[0], 'HH:mm');
    return hourA.diff(hourB);
  });

  const worksheet = XLSX.utils.aoa_to_sheet([
    ...headers,
    ...data
  ]);

  const colWidths = [20, ...selectedDevices.map(() => 15)];
  worksheet['!cols'] = colWidths.map(wch => ({ wch }));

  XLSX.utils.book_append_sheet(workbook, worksheet, "Hourly Consumption Summary");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `hourly_consumption_summary_${formattedDate}.xlsx`;
  link.click();
};

export const downloadPDFConsumptionHourly = (
  selectedDevices: string[],
  apiData: any,
  selectedDate: Dayjs,
  users: any,
  reportPeriodicity: string
) => {
  if (selectedDevices.length === 0) {
    console.error("Please select at least one device");
    return;
  }

  const doc = new jsPDF();
  const fontSize = 8;
  const fontStyle = "helvetica";
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const responsiveFontSize = fontSize * (pageWidth / 210);
  doc.setFont(fontStyle);
  doc.setFontSize(responsiveFontSize);

  // Add logo and header text
  const logoWidth = 40;
  const logoHeight = 20;
  doc.addImage(aacorplogo, "JPEG", 12, 3, logoWidth, logoHeight);
  doc.setFontSize(14);
  doc.text("A & A CORPORATION PVT. LTD.", 120, 20);

  doc.setFontSize(16);
  doc.text("Consumption Summary", pageWidth / 2, 35, { align: "center" });

  doc.setFontSize(fontSize);
  const formattedStartDate = selectedDate.startOf('day').format("DD-MM-YYYY HH:mm:ss");
  const formattedEndDate = selectedDate.endOf('day').format("DD-MM-YYYY HH:mm:ss");
  let startY = 45;
  doc.setFontSize(13);
  doc.text(`Report Duration: ${formattedStartDate} - ${formattedEndDate}`, 15, startY + 1);
  
  doc.setFontSize(13);
  doc.text(`Report Periodicity: ${reportPeriodicity}`, 15, startY + 8);
  startY += 35;
  const headers = ["Time", ...selectedDevices];
  const hourlyData: { [hour: string]: { [device: string]: number } } = {};

  selectedDevices.forEach((deviceName) => {
    const deviceData = apiData.find((device: { deviceName: string; }) => device.deviceName === deviceName);
    if (deviceData && deviceData.data && Array.isArray(deviceData.data.data) && deviceData.data.data.length > 0) {
      const template = deviceData.template;
      const filteredData = filterDataForSelectedDate(deviceData.data.data, selectedDate, template);

   
      if (filteredData.length > 0) {
        const hourData = processHourlyData(filteredData);
        const consumptionData = processConsumptionData(hourData, template);

        consumptionData.forEach((hourlyConsumption) => {
          // console.log('consumptionData',consumptionData)
          const startHour = dayjs(hourlyConsumption.hour).format('HH:00');
          const endHour = dayjs(hourlyConsumption.hour).add(1, 'hour').format('HH:00');
          const hourRange = `${startHour} - ${endHour}`;
          if (!hourlyData[hourRange]) {
            hourlyData[hourRange] = {};
          }
          hourlyData[hourRange][deviceName] = Number((hourlyConsumption.consumption).toFixed(3));
          //hourlyData[hourRange][deviceName] = Number(hourlyConsumption.consumption.toFixed(3));
        });
      }
    }
  });

  const data = Object.entries(hourlyData).map(([hour, devices]) => {
    return [
      hour,
      ...selectedDevices.map(device => {
        const value = devices[device];
        return typeof value === 'number' ? value.toFixed(3) : 'NA';
      })
    ];
  });

  data.sort((a, b) => {
    const hourA = dayjs(a[0].split(' - ')[0], 'HH:mm');
    const hourB = dayjs(b[0].split(' - ')[0], 'HH:mm');
    return hourA.diff(hourB);
  });

  (doc as any).autoTable({
    head: [headers],
    body: data,
    startY: 60,
  });

  const userName = users?.user?.name || 'Username';
  doc.setFontSize(8);
  doc.text(`Report Generated On:${new Date().toLocaleString()}`, 10, pageHeight - 10);
  doc.text(`Report Generated By: ${userName}`, 10, pageHeight - 5);

  doc.save("hourly_consumption_summary.pdf");
};


interface DailyData {
  date: string;
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstPositivecumulative: string;
  lastPositivecumulative: string;
}


interface DailyConsumptionData {
  date: string;
  firstReading: number;
  lastReading: number;
  consumption: number;
}

const getRelevantValue1 = (item: any, sensor: string, template: number): string => {
  if (template === 24 || template === 31) {
    if (item['Pos_Cumu_flow_Int'] !== undefined && item['Pos_Cumu_flow_Dec'] !== undefined) {
      return `${item['Pos_Cumu_flow_Int']}.${item['Pos_Cumu_flow_Dec']}`;
    }
  }

  if (template === 15) {
    return item['Totalizer'] !== undefined ? item['Totalizer'] : '0';
  }

  switch (sensor) {
    case 'total':
      return item['Forward Total Flow'] !== undefined ? item['Forward Total Flow'] : '0';
    case 'activeenergy(kwh)':
      return item['active_energy'] !== undefined ? item['active_energy'] : '0';
    case 'reactiveenergy(kvarh)':
      return item['reactive_energy'] !== undefined ? item['reactive_energy'] : '0';
    case 'apparentenergy(kvah)':
      return item['apparent_energy'] !== undefined ? item['apparent_energy'] : '0';
    case 'totalizer':
      return item['totalizer'] !== undefined ? item['totalizer'] : '0';
    default:
      return '0';
  }
};

interface DailyData {
  date: string;
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstPositivecumulative: string;
  lastPositivecumulative: string;
}

const filterDataForDateRange = (data: any[], startDate: Dayjs, endDate: Dayjs, sensor: string, template: number): DailyData[] => {
  // Extend the filter to include one day before the start date
  const extendedStartDate = startDate.subtract(1, 'day');
  
  const filteredData = data.filter((item) => {
    if (!item || !item.timeStamp) return false;
    const itemDate = dayjs(item.timeStamp);
    return itemDate.isValid() && itemDate.isSameOrAfter(extendedStartDate) && itemDate.isSameOrBefore(endDate);
  });

  filteredData.sort((a, b) => 
    dayjs(a.timeStamp).valueOf() - dayjs(b.timeStamp).valueOf()
  );

  const result: DailyData[] = [];
  let previousDayLastReading: string | null = null;
  let previousDayLastTimestamp: string | null = null;

  filteredData.forEach((item, index) => {
    const itemDate = dayjs(item.timeStamp);
    const dateString = itemDate.format('YYYY-MM-DD');
    
    if (itemDate.isBefore(startDate)) {
      // This is data from the day before the start date
      previousDayLastReading = getRelevantValue1(item, sensor, template);
      previousDayLastTimestamp = item.timeStamp;
      return; // Skip to next iteration
    }

    const existingIndex = result.findIndex((dailyData) => dailyData.date === dateString);
    const currentValue = getRelevantValue1(item, sensor, template);

    if (existingIndex === -1) {
      // New day
      const newDayData: DailyData = {
        date: dateString,
        firstTimeStamp: previousDayLastTimestamp || item.timeStamp,
        lastTimeStamp: item.timeStamp,
        firstPositivecumulative: previousDayLastReading || currentValue,
        lastPositivecumulative: currentValue,
      };
      result.push(newDayData);
    } else {
      // Update existing day
      result[existingIndex].lastTimeStamp = item.timeStamp;
      result[existingIndex].lastPositivecumulative = currentValue;
    }

    // Store last reading of the day for next day's first reading
    if (index === filteredData.length - 1 || dayjs(filteredData[index + 1].timeStamp).format('YYYY-MM-DD') !== dateString) {
      previousDayLastReading = currentValue;
      previousDayLastTimestamp = item.timeStamp;
    }
  });

  // console.log('filterDataForDateRange output:', result);
  return result;
};


const processConsumptionDataDaily = (inputData: DailyData[], template: number): DailyConsumptionData[] => {
  const result: DailyConsumptionData[] = [];

  const getScaledValue = (value: string, scaleDown: boolean): number => {
    const parsedValue = parseFloat(value);
    if (template === 24 || template === 31 || template === 15) {
      return parsedValue;
    } else if (template === 14) {
      return parsedValue;
    } else {
      return parsedValue / 1000;
    }
  };

  for (let i = 0; i < inputData.length; i++) {
    const currentDay = inputData[i];
    const nextDay = inputData[i + 1];

    if (nextDay) {
      const firstReading = getScaledValue(currentDay.firstPositivecumulative, true);
      const lastReading = getScaledValue(nextDay.firstPositivecumulative, false);
      const consumption = Math.abs(lastReading - firstReading);

      result.push({
        date: currentDay.date,
        consumption: parseFloat(consumption.toFixed(3)),
        firstReading: parseFloat(firstReading.toFixed(3)),
        lastReading: parseFloat(lastReading.toFixed(3)),
      });
    } else {
      const firstReading = getScaledValue(currentDay.firstPositivecumulative, true);
      const lastReading = getScaledValue(currentDay.lastPositivecumulative, false);
      const consumption = Math.abs(lastReading - firstReading);

      result.push({
        date: currentDay.date,
        consumption: parseFloat(consumption.toFixed(3)),
        firstReading: parseFloat(firstReading.toFixed(3)),
        lastReading: parseFloat(lastReading.toFixed(3)),
      });
    }
  }

  return result;
};

export const generateExcelConsumptionReportDaily = (
  selectedDevices: string[],
  apiData: any,
  startDate: Dayjs,
  endDate: Dayjs,
  users: any,
  reportPeriodicity: string,
  sensor: string
) => {
  if (selectedDevices.length === 0) {
    console.error("Please select at least one device");
    return;
  }

  const workbook = XLSX.utils.book_new();

  const userName = users?.user?.name || 'Username';
  const formattedStartDate = startDate.format('DD-MMM-YYYY HH:mm');
  const formattedEndDate = endDate.format('DD-MMM-YYYY HH:mm');

  const headers = [
    ["Report Header", "Consumption Summary"],
    ["Report Periodicity", reportPeriodicity],
    ["Report Duration", `${formattedStartDate} to ${formattedEndDate}`],
    ["Report Generated", userName],
    [""],
    ["Device name", ...selectedDevices.flatMap(device => [device, "", ""])],
    ["Date", ...selectedDevices.flatMap(() => ["First Reading", "Last Reading", "Consumption"])]
  ];

  const dailyData: { [date: string]: { [device: string]: DailyConsumptionData } } = {};

  selectedDevices.forEach((deviceName) => {
    const deviceData = apiData.find((device: { deviceName: string; }) => device.deviceName === deviceName);
    if (deviceData && deviceData.data && Array.isArray(deviceData.data.data) && deviceData.data.data.length > 0) {
      const template = deviceData.template;
      const filteredData = filterDataForDateRange(deviceData.data.data, startDate, endDate, sensor, template);
      if (filteredData.length > 0) {
        //const consumptionData = processConsumptionDataDaily(filteredData);
        const consumptionData = processConsumptionDataDaily(filteredData, template);
        consumptionData.forEach((dailyConsumption) => {
          const date = dayjs(dailyConsumption.date).format('DD-MM-YY');
          if (!dailyData[date]) {
            dailyData[date] = {};
          }
          dailyData[date][deviceName] = dailyConsumption;
        });
      }
    }
  });

  const data = Object.entries(dailyData).map(([date, devices]) => {
    return [
      date,
      ...selectedDevices.flatMap(device => [
        devices[device]?.firstReading.toFixed(3)  || 'NA',
        devices[device]?.lastReading.toFixed(3)  || 'NA',
        devices[device]?.consumption.toFixed(3) || 'NA'
      ])
    ];
  });

  data.sort((a, b) => dayjs(a[0], 'DD-MM-YY').diff(dayjs(b[0], 'DD-MM-YY')));

  const worksheet = XLSX.utils.aoa_to_sheet([
    ...headers,
    ...data
  ]);

  const colWidths = [15, ...selectedDevices.flatMap(() => [15, 15, 15])];
  worksheet['!cols'] = colWidths.map(wch => ({ wch }));

  const merges: XLSX.Range[] = [];
  let colIndex = 1;
  selectedDevices.forEach((device, index) => {
    merges.push({
      s: { r: 5, c: colIndex },
      e: { r: 5, c: colIndex + 2 }
    });
    const cellRef = XLSX.utils.encode_cell({r: 5, c: colIndex});
    if (!worksheet[cellRef]) worksheet[cellRef] = {};
    worksheet[cellRef].s = { alignment: { horizontal: 'center' } };
    colIndex += 3;
  });
  worksheet['!merges'] = merges;

  XLSX.utils.book_append_sheet(workbook, worksheet, "Daily Consumption Summary");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `daily_consumption_summary_${formattedStartDate}_to_${formattedEndDate}.xlsx`;
  link.click();
};

export const downloadPDFConsumptionDaily = (
  selectedDevices: string[],
  apiData: any,
  startDate: Dayjs,
  endDate: Dayjs,
  users: any,
  reportPeriodicity: string,
  sensor: string
) => {
  
  if (selectedDevices.length === 0) {
    console.error("Please select at least one device");
    return;
  }
  if (selectedDevices.length > 2) {
    toast.error("Please select only 2 devices");
    return;
  }

  const selectedDevicesLimited = selectedDevices.slice(0, 2);

  if (selectedDevicesLimited.length === 0) {
    console.error("Please select at least one device");
    return;
  }

  const doc = new jsPDF();
  const fontSize = 8;
  const fontStyle = "helvetica";
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const responsiveFontSize = fontSize * (pageWidth / 210);
  doc.setFont(fontStyle);
  doc.setFontSize(responsiveFontSize);

  const logoWidth = 40;
  const logoHeight = 20;
  doc.addImage(aacorplogo, "JPEG", 12, 3, logoWidth, logoHeight);
  doc.setFontSize(14);
  doc.text("A & A CORPORATION PVT. LTD.", 120, 20);

  doc.setFontSize(16);
  doc.text("Consumption Summary", pageWidth / 2, 35, { align: "center" });

  doc.setFontSize(fontSize);
  const formattedStartDate = startDate.format("DD-MMM-YYYY HH:mm");
  const formattedEndDate = endDate.format("DD-MMM-YYYY HH:mm");
  let startY = 45;
  doc.setFontSize(13);
  doc.text(`Report Duration: ${formattedStartDate} - ${formattedEndDate}`, 15, startY + 1);
  
  doc.setFontSize(13);
  doc.text(`Report Periodicity: ${reportPeriodicity}`, 15, startY + 8);
  startY += 35;

  const headers = [
    ["Device name", ...selectedDevicesLimited.flatMap(device => [device, "", ""])],
    ["Date", ...selectedDevicesLimited.flatMap(() => ["First Reading", "Last Reading", "Consumption"])]
  ];

  const dailyData: { [date: string]: { [device: string]: DailyConsumptionData } } = {};

  selectedDevicesLimited.forEach((deviceName) => {
    const deviceData = apiData.find((device: { deviceName: string; }) => device.deviceName === deviceName);
    if (deviceData && deviceData.data && Array.isArray(deviceData.data.data) && deviceData.data.data.length > 0) {
      const template = deviceData.template;
      const filteredData = filterDataForDateRange(deviceData.data.data, startDate, endDate, sensor, template);
      if (filteredData.length > 0) {
        //const consumptionData = processConsumptionDataDaily(filteredData);
        const consumptionData = processConsumptionDataDaily(filteredData, template);
        consumptionData.forEach((dailyConsumption) => {
          const date = dayjs(dailyConsumption.date).format('DD-MM-YY');
          if (!dailyData[date]) {
            dailyData[date] = {};
          }
          dailyData[date][deviceName] = dailyConsumption;
        });
      }
    }
  });

  const data = Object.entries(dailyData).map(([date, devices]) => {
    return [
      date,
      ...selectedDevicesLimited.flatMap(device => [
        devices[device]?.firstReading !== undefined ? devices[device].firstReading.toFixed(3) : 'NA',
        devices[device]?.lastReading !== undefined ? devices[device].lastReading.toFixed(3) : 'NA',
        devices[device]?.consumption !== undefined ? devices[device].consumption.toFixed(3) : 'NA'
      ])
    ];
  });

  data.sort((a, b) => dayjs(a[0], 'DD-MM-YY').diff(dayjs(b[0], 'DD-MM-YY')));

  (doc as any).autoTable({
    head: headers,
    body: data,
    startY: 60,
    theme: 'grid',
    styles: { fontSize: 8, cellPadding: 1 },
    columnStyles: { 0: { cellWidth: 20 } },
  });

  const userName = users?.user?.name || 'Username';
  doc.setFontSize(8);
  doc.text(`Report Generated On: ${new Date().toLocaleString()}`, 10, pageHeight - 10);
  doc.text(`Report Generated By: ${userName}`, 10, pageHeight - 5);

  doc.save("daily_consumption_summary.pdf");
};

